<template functional>
    <div class="bubble-container" :class="props.position">
        <svg v-if="props.position === 'left'" viewBox="0 -11 512 512" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m501 245c0 135.308594-109.691406 245-245 245s-245-109.691406-245-245 109.691406-245 245-245 245 109.691406 245 245zm0 0"
                fill="#30d6ce"
            />
            <path
                d="m482 170c0 5.523438-4.476562 10-10 10s-10-4.476562-10-10c0-82.710938-67.289062-150-150-150h-112c-82.710938
                0-150 67.289062-150 150 0 5.523438-4.476562 10-10 10s-10-4.476562-10-10c0-93.738281 76.261719-170 170-170h112c93.738281
                0 170 76.261719 170 170zm0 0"
                fill="#025f80"
            />
            <path
                d="m512 147.75v40c0 16.566406-13.429688 30-30 30h-12c0 4.992188-4.4765629.042969-10 9.042969s-10-4.050781-10-9.042969h-
                18c-5.523438 0-10-4.476562-10-10v-80c0-5.523438 4.476562-10 10-10h14.902344l-3.261719-8.964844c-2.832031-7.785156
                1.179687-16.394531 8.964844-19.226562 7.785156-2.832032 16.390625 1.179687 19.226562 8.964844l6.996094 19.226562h3.171875c16.570312
                0 30 13.433594 30 30zm-432-30h-14.902344l3.261719-8.964844c2.832031-7.785156-1.179687-16.394531-8.964844-19.226562-7.785156-
                2.832032-16.390625 1.179687-19.226562 8.964844l-6.996094 19.226562h-3.171875c-16.570312 0-30 13.433594-30 30v40c0 16.566406
                13.429688 30 30 30h50c5.523438 0 10-4.476562 10-10v-80c0-5.523438-4.476562-10-10-10zm0 0"
                fill="#ff6466"
            />
            <path
                d="m482 147.75v40c0 16.566406-13.429688 30-30 30h-20c-5.523438 0-10-4.476562-10-10v-80c0-5.523438 4.476562-10
                10-10h20c16.570312 0 30 13.433594 30 30zm-452 40v-40c0-12.386719 7.511719-23.015625 18.226562-27.59375.464844-
                .195312.773438-.644531.773438-1.148438 0-.695312-.5625-1.257812-1.253906-1.257812h-17.746094c-16.570312 0-30
                13.433594-30 30v40c0 16.570312 13.429688 30 30 30h30c-16.570312 0-30-13.433594-30-30zm0 0"
                fill="#ff393a"
            />
            <path
                d="m286 328.75c0 22.089844-13.429688 40-30 40s-30-17.910156-30-40 13.429688-40 30-40 30 17.910156 30 40zm0 0"
                fill="#0069a3"
            />
            <path
                d="m286 328.75c0 16.402344-7.40625 30.488281-18 36.660156-10.59375-6.171875-18-20.257812-18-36.660156s7.40625-30.488281
                18-36.660156c10.59375 6.171875 18 20.257812 18 36.660156zm0 0"
                fill="#08c"
            />
            <path
                d="m447 173.75c0 77.320312-62.679688 140-140 140h-102c-77.320312 0-140-62.679688-140-140s62.679688-140 140-140h102c77.320312 0
                140 62.679688 140 140zm-135 160h-112c-54.480469 0-101.683594 31.121094-124.820312 76.558594 44.796874 48.96875 109.21875 79.691406
                180.820312 79.691406s136.023438-30.722656 180.820312-79.691406c-23.136718-45.4375-70.339843-76.558594-124.820312-76.558594zm0 0"
                fill="#97f0f2"
            />
            <path
                d="m307 313.75h-63.019531c-77.320313 0-140-62.679688-140-140s62.679687-140 140-140h63.019531c77.320312 0 140 62.679688
                140 140s-62.679688 140-140 140zm-51 176.25c71.601562 0 136.023438-30.722656 180.820312-79.691406-23.136718-45.4375-70.339843-
                76.558594-124.820312-76.558594h-72c-64.859375 0-119.402344 44.105469-135.300781 103.960938 41.65625 32.753906 94.199219
                52.289062 151.300781 52.289062zm0 0"
                fill="#c0fbff"
            />
            <path
                d="m346 444c0 10.910156-1.945312 21.359375-5.503906 31.035156-26.339844 9.679688-54.800782 13.964844-84.496094
                13.964844s-58.15625-4.285156-84.496094-13.964844c-3.558594-9.675781-5.503906-20.125-5.503906-31.035156 0-49.707031
                40.292969-90 90-90s90 40.292969 90 90zm0 0"
                fill="#08c"
            />
            <path
                d="m322 258.75h-132c-38.660156 0-70-31.339844-70-70v-30c0-38.660156 31.339844-70 70-70h132c38.660156 0 70 31.339844 70 70v30c0 38.660156-31.339844 70-70 70zm-96.984375 207.882812c-21.070313-4.699218-36.421875-23.148437-36.515625-44.738281 0-.132812 0-.261719 0-.394531 0-19.105469 5.957031-36.816406 16.109375-51.390625-23.335937 16.261719-38.609375 43.289063-38.609375 73.890625 0 10.910156 1.945312 21.359375 5.503906 31.035156 26.339844 9.679688 54.800782 14.964844 84.496094 14.964844 29.6875 0 58.136719-5.28125 84.46875-14.953125 1.355469-3.691406 2.5-7.484375 3.371094-11.386719-20.804688 5.75-42.707032 8.839844-65.339844 8.839844-18.371094 0-36.269531-2.027344-53.484375-5.867188zm0 0"
                fill="#006cbc"
            />
            <path
                d="m322 258.75h-102c-38.660156 0-70-31.339844-70-70v-30c0-38.660156 31.339844-70 70-70h102c38.660156 0 70 31.339844 70 70v30c0
                38.660156-31.339844 70-70 70zm0 0"
                fill="#08c"
            />
            <path
                d="m284.285156 201.929688c3.90625 3.90625 3.90625 10.234374 0 14.140624-15.597656 15.597657-40.972656 15.597657-56.570312 0-3.902344-3.902343-3.902344-10.234374 0-14.140624 3.90625-3.90625 10.238281-3.90625 14.144531 0 7.796875 7.796874 20.484375 7.796874 28.285156 0 1.953125-1.953126 4.511719-2.929688 7.070313-2.929688s5.117187.976562 7.070312 2.929688zm-58.105468-61.234376c-15.597657-15.59375-40.976563-15.59375-56.570313 0-3.90625 3.90625-3.90625 10.238282 0 14.144532 3.90625 3.902344 10.238281 3.902344 14.144531 0 7.796875-7.800782 20.484375-7.800782 28.28125 0 1.953125 1.953125 4.511719 2.929687 7.070313 2.929687 2.558593 0 5.121093-.976562 7.074219-2.929687 3.902343-3.90625 3.902343-10.238282 0-14.144532zm116.210937 0c-15.597656-15.59375-40.972656-15.59375-56.5703130-3.902343 3.90625-3.902343 10.238282 0 14.144532 3.90625 3.902344 10.238282 3.902344 14.144532 0 7.796875-7.800782 20.484375-7.800782 28.285156 0 1.953125 1.953125 4.511719 2.929687 7.070312 2.929687 2.558594 0 5.117188-.976562 7.070313-2.929687 3.90625-3.90625 3.90625-10.238282 0-14.144532zm0 0"
                fill="#30d6ce"
            />
            <path
                d="m271 437v52.546875c-4.964844.300781-9.960938.453125-15 .453125s-10.035156-.152344-15-.453125v-52.546875c0-8.285156 6.714844-15 15-15s15 6.714844 15 15zm0 0"
                fill="#84deea"
            />
            <path
                d="m271 437v22c0 8.285156-6.714844 15-15 15s-15-6.714844-15-15v-22c0-8.285156 6.714844-15 15-15s15 6.714844 15 15zm-15-27c9.941406
                0 18-8.058594 18-18s-8.058594-18-18-18-18 8.058594-18 18 8.058594 18 18 18zm0 0"
                fill="#fff5f5"
            />
            <path
                d="m470 217.75v20c0 22.054688-17.945312 40-40 40h-170v-20h170c11.027344 0 20-8.972656 20-20v-20zm0 0"
                fill="#025f80"
            />
            <path
                d="m288.945312 283.109375h-65.890624c-9.417969 0-17.054688-7.636719-17.054688-17.054687 0-9.417969 7.636719-17.054688
                17.054688-17.054688h65.890624c9.417969 0 17.054688 7.636719 17.054688 17.054688 0 9.421874-7.636719 17.054687-17.054688
                17.054687zm0 0"
                fill="#ff6466"
            />
            <path
                d="m288.945312 283.109375h-35.890624c-9.417969 0-17.054688-7.636719-17.054688-17.054687 0-9.417969 7.636719-17.054688
                17.054688-17.054688h35.890624c9.417969 0 17.054688 7.636719 17.054688 17.054688 0 9.421874-7.636719 17.054687-17.054688
                17.054687zm0 0"
                fill="#ff895a"
            />
        </svg>
        <div class="bubble shadow-md" :class="props.position">
            <p class="mb-2 text-xs text-red-200">
                <slot name="time" />
            </p>
            <slot name="message" />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        position: {
            type:    String,
            default: 'left',
        },
    },
};
</script>
<style scoped>
.bubble-container {
    display: flex;
    align-items: flex-end;
    margin-top: 12px;
    margin-bottom: 12px;
}

.bubble-container.right {
    justify-content: flex-end;
}

.bubble-container svg {
    width: 30px;
    height: 30px;
}

.bubble {
    display: inline-block;
    border-radius: 10px;
    padding: 7px 15px 9px;
    color: #f0f0f0;
    font-size: 13px;
    margin-left: 10px;
    max-width: 70%;
}

.bubble.left {
    background-image: linear-gradient(60deg, #f26364, #f57879);
}

.bubble.right {
    background-color: #ff9a9a;
    color: #fff;
    box-shadow: 0 1px 6px -1px rgba(0, 0, 0, 0.15), 0 2px 4px -1px rgba(0, 0, 0, 0.08);
    max-width: 88%;
}

</style>
